"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onTransitionEnd = exports.parseHTML = exports.html = exports.css = exports.toggleClass = exports.removeClass = exports.addClass = exports.hasClass = void 0;
function hasClass(element, class_name) {
    return element.classList.contains(class_name);
}
exports.hasClass = hasClass;
function addClass(element, class_name) {
    element.classList.add(class_name);
}
exports.addClass = addClass;
function removeClass(element, class_name) {
    element.classList.remove(class_name);
}
exports.removeClass = removeClass;
function toggleClass(element, class_name, override) {
    // override = true -> add class 
    // override = false -> remove class
    // override = undefined -> just toggle
    var canAdd = true;
    var canRemove = true;
    if (typeof override === "boolean") {
        if (override) {
            canRemove = false;
        }
        else {
            canAdd = false;
        }
    }
    if (hasClass(element, class_name)) {
        canRemove && removeClass(element, class_name);
    }
    else {
        canAdd && addClass(element, class_name);
    }
}
exports.toggleClass = toggleClass;
function style_str_to_obj(str) {
    var new_styles = {};
    var style_strings = str.split(";");
    style_strings.forEach(function (style_string) {
        var _a = style_string.split("="), k = _a[0], v = _a[1];
        new_styles[k] = v;
    });
    return new_styles;
}
function style_obj_to_str(obj) {
    var new_styles = [];
    Object.keys(obj).forEach(function (key) {
        new_styles.push(key + "=" + obj[key]);
    });
    return new_styles.join(";");
}
function css(element, props) {
    var current_styles = element.getAttribute("style");
    if (current_styles) {
        var current_style_obj = __assign(__assign({}, style_str_to_obj(current_styles)), props);
        element.setAttribute("style", style_obj_to_str(current_style_obj));
    }
}
exports.css = css;
function html(element, set_to) {
    element.innerHTML = set_to;
}
exports.html = html;
function parseHTML(html) {
    var t = document.createElement('template');
    t.innerHTML = html;
    return t.content.cloneNode(true);
}
exports.parseHTML = parseHTML;
function onTransitionEnd(element, callback) {
    function whichTransitionEvent() {
        var t;
        var el = document.createElement('fakeelement');
        var transitions = {
            'transition': 'transitionend',
            'OTransition': 'oTransitionEnd',
            'MozTransition': 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd'
        };
        for (t in transitions) {
            if (el.style[t] !== undefined) {
                return transitions[t];
            }
        }
    }
    var listener;
    var transitionEnd = whichTransitionEvent();
    if (transitionEnd) {
        listener = element.addEventListener(transitionEnd, callback, {
            passive: true,
            once: true,
        });
    }
}
exports.onTransitionEnd = onTransitionEnd;
