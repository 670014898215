"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var scrollfreeze_1 = require("./scrollfreeze");
var util_1 = require("./util");
function kickWindowEvent(name, data) {
    if (data === void 0) { data = {}; }
    var event = new Event("modal::" + name, data);
    window.dispatchEvent(event);
}
function isElement(element) {
    return element instanceof Element || element instanceof HTMLDocument;
}
function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
function getModalFrame() {
    var modalWrapper = document.getElementById('modal__wrapper');
    var modalInner = document.getElementById('modal__inner');
    if (!modalWrapper || !modalInner) {
        document.body.append(util_1.parseHTML("<div id=\"modal__wrapper\" class=\"hashmodal__wrapper\">\n        <a id=\"modal__background\" href=\"#closemodal\" class=\"hashmodal__background\"></a>\n        <div id=\"modal\" class=\"hashmodal\">\n          <a href=\"#closemodal\" class=\"hashmodal__close\" id=\"modal__close\"></a>\n          <div class=\"hashmodal__inner\" id=\"modal__inner\"></div>\n        </div>\n      </div>"));
        modalWrapper = document.getElementById('modal__wrapper');
        modalInner = document.getElementById('modal__inner');
        if (!modalWrapper || !modalInner) {
            throw new Error("Can't create modals!");
        }
    }
    return {
        wrapper: modalWrapper,
        inner: modalInner,
    };
}
var hashes = {};
var initialised = false;
function addModal(hashTrigger, contents, options) {
    if (options === void 0) { options = { clearOnClose: false }; }
    if (typeof hashTrigger !== 'string')
        throw new Error('Hash trigger must be a string');
    if (typeof contents !== 'string' && !isElement(contents) && !isFunction(contents))
        throw new Error('Contents must be an html string or dom node, or a function which returns an html string or dom node when passed the hash!');
    if (typeof hashes[hashTrigger] !== 'undefined')
        throw new Error("Hashtrigger \"" + hashTrigger + "\" already used!");
    if (typeof options !== 'undefined') {
        if (typeof options !== 'object')
            throw new Error('Options must be an object');
        if (typeof options.clearOnClose !== 'undefined' && typeof options.clearOnClose !== 'boolean')
            throw new Error('clearOnClose Option must be a boolean');
    }
    hashes[hashTrigger] = {
        contents: contents,
        options: options,
    };
    if (!(modalWrapper && modalInner && initialised))
        return;
    if (getCurrentHash() === hashTrigger) {
        hashHandler();
    }
    return true;
}
;
var modalWrapper;
var modalInner;
function init() {
    var modal_components = getModalFrame();
    modalWrapper = modal_components.wrapper;
    modalInner = modal_components.inner;
    initialised = true;
    window.addEventListener("hashchange", hashHandler);
    hashHandler();
    kickWindowEvent('ready');
}
var open = false;
var lastOpened;
function toggleModal(opening) {
    if (!(modalWrapper && modalInner && initialised))
        return;
    if (typeof opening === 'undefined') {
        opening = !open; // if it's closed should be opening
    }
    else if (opening === open) {
        return; // if open should not be opening
    }
    if (opening) {
        util_1.toggleClass(modalWrapper, 'block', true);
        setTimeout(function () {
            if (!(modalWrapper && modalInner && initialised))
                return;
            util_1.toggleClass(modalWrapper, 'hide', !opening);
            util_1.toggleClass(modalWrapper, 'show', opening);
        }, 0);
        open = true;
    }
    else { // closing
        util_1.onTransitionEnd(modalWrapper, function () {
            if (!(modalWrapper && modalInner && initialised))
                return;
            util_1.toggleClass(modalWrapper, 'block', opening);
        });
        util_1.toggleClass(modalWrapper, 'hide', !opening);
        util_1.toggleClass(modalWrapper, 'show', opening);
        open = false;
    }
    scrollfreeze_1.default.toggleFrozen(open);
}
function getCurrentHash() {
    var hash = window.location.hash;
    if (hash.indexOf('#') >= 0) {
        var _a = hash.split('#'), theHash = _a[0], hashString = _a[1];
        if (hashString && typeof hashString === 'string') {
            var hashTrigger = hashString.trim();
            return hashTrigger;
        }
    }
    return false;
}
function setModalContents(contents) {
    if (!(modalWrapper && modalInner && initialised))
        return;
    util_1.html(modalInner, '');
    if (typeof contents === "function") {
        contents = contents();
    }
    if (typeof contents === 'string') {
        var html_1 = util_1.parseHTML(contents);
        if (html_1) {
            modalInner.append(html_1);
        }
    }
    else if (Array.isArray(contents)) {
        contents.forEach(function (item) {
            if (typeof item === 'string') {
                var itemHtml = util_1.parseHTML(item);
                if (itemHtml) {
                    modalInner.append(itemHtml);
                }
            }
            else if (isElement(item)) {
                modalInner.append(item);
            }
        });
    }
    else if (!!contents && isElement(contents)) {
        modalInner.append(contents);
    }
}
function hashHandler() {
    var hash = getCurrentHash();
    if (hash) {
        if (hash === 'closemodal') {
            toggleModal(false);
            if (lastOpened) {
                var prevModal = hashes[lastOpened];
                if (prevModal && prevModal.options && prevModal.options.clearOnClose) {
                    setModalContents();
                }
            }
            kickWindowEvent('close');
        }
        else if (hash.length > 0 && typeof hashes[hash] !== 'undefined') {
            if (lastOpened === hash) {
                var prevModal = hashes[lastOpened];
                if (prevModal.options && prevModal.options.clearOnClose) {
                    setModalContents(hashes[hash].contents);
                }
            }
            else {
                setModalContents(hashes[hash].contents);
            }
            lastOpened = hash;
            toggleModal(true);
            kickWindowEvent('open');
        }
    }
}
exports.default = {
    init: init,
    addModal: addModal,
};
