"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var modals_1 = require("./modules/modals");
function ready(callbackFunc) {
    if (document.readyState !== 'loading') {
        // Document is already ready, call the callback directly
        callbackFunc();
    }
    else if (document.addEventListener) {
        // All modern browsers to register DOMContentLoaded
        document.addEventListener('DOMContentLoaded', callbackFunc);
    }
    else {
        // Old IE browsers
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
                callbackFunc();
            }
        });
    }
}
window.modals = modals_1.default;
ready(function () {
    modals_1.default.init();
});
