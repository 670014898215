"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleFrozen = void 0;
var scrollPosition = 0;
var util_1 = require("./util");
function toggleFrozen(force, options) {
    if (options === void 0) { options = {}; }
    var body = document.body;
    var html = document.documentElement;
    if (!body || !html)
        return;
    var freeze;
    if (typeof force !== 'boolean') {
        var currentState = util_1.hasClass(body, 'noscroll');
        freeze = !currentState;
    }
    else {
        freeze = force;
    }
    if (freeze) {
        if (typeof options.YOffset === 'number') {
            scrollPosition = options.YOffset;
        }
        else {
            scrollPosition = window.pageYOffset;
        }
        util_1.css(body, { top: "-" + scrollPosition + "px" });
        util_1.toggleClass(body, 'noscroll', true);
    }
    else {
        util_1.toggleClass(body, 'noscroll', false);
        util_1.css(body, { top: 'auto' });
        util_1.css(html, { 'scroll-behavior': 'auto' });
        window.scrollTo({
            left: 0,
            top: scrollPosition,
            behavior: 'auto',
        });
        util_1.css(html, { 'scroll-behavior': '' });
    }
}
exports.toggleFrozen = toggleFrozen;
exports.default = {
    toggleFrozen: toggleFrozen,
};
